<template>
    <div class="ui-optgroup">
        <slot name="default"></slot>
    </div>
</template>

<script>
export default {
    name: 'ui-optgroup'
}
</script>